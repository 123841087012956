.comingSoonBadge {
  rect {
    // stroke: var(--c-light);
    // stroke-width: 1px;
  }
  path {
    fill: var(--c-light);
  }
}
.typefaceBadge {
  background-color: $c-purple;
  color: $c-light;
  text-transform: uppercase;
  padding: .7em 1.5em .6em;
  font-size: 2.4rem;
  line-height: 1em;
  border-radius: 2em;
  text-align: center;

  .home--cards--fontExample &, .fontsPage--block & {
    position: absolute;
    top: 11%;
    right: 4%;
    transform: rotate(5deg);
  }
  .fontsPage--block & { 
    font-size: 2.1rem;
    top: 4%;
  }
}
