.chartwellToolsPage {
  padding: 7.5rem $main-padding-horizontal 7.5rem;
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  // column-gap: 6fr;
  font-size: 2.8rem;
}
.syntaxConverter {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
}
.syntaxConverter--chartSelection {
  grid-column: 1 / 1;
}
.syntaxConverter--main {
  grid-column: 2 / 4;
}
.syntaxConverter--fields {
  margin-bottom: 2rem;
}
.syntaxConverterPreview--chart {
  font-size: 20rem;
}

$cw-c-bg: #ffffff;
$cw-c-black: #2D2F3E;
$cw-c-gray: #BCBEC0;
$cw-c-gray-2: #EBEBEB;
$cw-c-blue: #4BADF8;
$cw-c-orange: #F09049;
$cw-c-green: #4CA44A;
$cw-c-yellow: #FFFD57;
$cw-c-red: #FF0000;
@mixin chartwell_multicolors() {
  :nth-child(5n+1) {
    color: $cw-c-blue;
  }
  :nth-child(5n+2) {
    color: $cw-c-orange;
  }
  :nth-child(5n+3) {
    color: $cw-c-yellow;
  }
  :nth-child(5n+4) {
    color: $cw-c-green;
  }
  :nth-child(5n+5) {
    color: $cw-c-gray-2;
  }
}
@mixin chartwell_multicolors_gray() {
  :nth-child(5n+1) {
    color: $cw-c-black;
  }
  :nth-child(5n+2) {
    color: mix($cw-c-black, $cw-c-bg, 70%);
  }
  :nth-child(5n+3) {
    color: mix($cw-c-black, $cw-c-bg, 50%);
  }
  :nth-child(5n+4) {
    color: mix($cw-c-black, $cw-c-bg, 30%);
  }
  :nth-child(5n+5) {
    color: mix($cw-c-black, $cw-c-bg, 20%);
  }
}
.chartwellChart--subValue-1 {
  color: $cw-c-orange;
}
.chartwellChart--subValue-2 {
  color: $cw-c-yellow;
}
.chartwellChart--subValue-3 {
  color: $cw-c-green;
}
.chartwellChart--subValue-4 {
  color: $cw-c-gray-2;
}

.chartwellChart {
  font-variant-ligatures: discretionary-ligatures;
}
.chartwellChart--grid {
  color: $cw-c-gray;
}
.chartwellChart--ref {
  color: $cw-c-red;
}
.chartwellChart--arcs {
  font-family: 'Chartwell Arcs Web';
  .chartwellChart--grid {
    color: $cw-c-black;
  }
}
.chartwellChart--areas {
  font-family: 'Chartwell Areas Web';
}
.chartwellChart--areas-ranges {
  font-family: 'Chartwell AreasRanges Web';
}
.chartwellChart--bars {
  font-family: 'Chartwell Bars Web';
  color: $cw-c-blue;
}
.chartwellChart--bars-3d {
  font-family: 'Chartwell Bars3D Web';
  @include chartwell_multicolors_gray();
}
.chartwellChart--histograms {
  font-family: 'Chartwell Histograms Web';
  color: $cw-c-blue;
}
.chartwellChart--lines-a {
  font-family: 'Chartwell LinesA Web';
}
.chartwellChart--lines, .chartwellChart--lines-b {
  font-family: 'Chartwell LinesB Web';
}
.chartwellChart--lines-c {
  font-family: 'Chartwell LinesC Web';
}
.chartwellChart--lines-d {
  font-family: 'Chartwell LinesD Web';
}
.chartwellChart--lines-e {
  font-family: 'Chartwell LinesE Web';
}
.chartwellChart--lines-f {
  font-family: 'Chartwell LinesF Web';
}
.chartwellChart--pies {
  font-family: 'Chartwell Pies Web';
  @include chartwell_multicolors();

  .chartwellChart--ref {
    color: $cw-c-bg;
  }
}
.chartwellChart--progress-bars {
  font-family: 'Chartwell ProgressBars Web';
}
.chartwellChart--progress-boxes {
  font-family: 'Chartwell ProgressBoxes Web';
}
.chartwellChart--progress-dots {
  font-family: 'Chartwell ProgressDots Web';
}
.chartwellChart--progress-squares {
  font-family: 'Chartwell ProgressSquares Web';
}
.chartwellChart--progress-stars {
  font-family: 'Chartwell ProgressStars Web';
}
.chartwellChart--radar {
  font-family: 'Chartwell Radar Web';
}
.chartwellChart--rings {
  font-family: 'Chartwell Rings Web';
}
.chartwellChart--rose {
  font-family: 'Chartwell Rose Web';
  @include chartwell_multicolors();
  .chartwellChart--grid {
    color: $cw-c-gray;
  }
}
.chartwellChart--scatter {
  font-family: 'Chartwell Scatter Web';
}
.chartwellChart--steps-a {
  font-family: 'Chartwell StepsA Web';
}
.chartwellChart--steps, .chartwellChart--steps-b {
  font-family: 'Chartwell StepsB Web';
}
.chartwellChart--steps-c {
  font-family: 'Chartwell StepsC Web';
}
.chartwellChart--steps-d {
  font-family: 'Chartwell StepsD Web';
}
.chartwellChart--steps-e {
  font-family: 'Chartwell StepsE Web';
}
.chartwellChart--steps-f {
  font-family: 'Chartwell StepsF Web';
}

