footer {
  position: relative;
  padding: 16.5rem 0 5rem;
  background-color: var(--c-gray-10);
  color: var(--c-dark);
  font-size: 2rem;

  ul, li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}
.footer--backToTopBtn {
  position: absolute;
  top: 3rem;
  right: $main-padding-horizontal;
}
.footer--logo {
  position: relative;
  left: -1vw;
  display: block;
  width: 44vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 13rem;

  svg {
    fill: var(--c-dark);
  }
}
.footer--main {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 2rem;
  border-top: 1px solid var(--c-gray-9);
  padding: 5rem $main-padding-horizontal;
}
.footer--main--links {
  grid-column: 1 / 6;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  line-height: 1.6em;

  @include breakpoint($media-sm) {
    grid-column: 1 / 7;
    grid-template-columns: repeat(3, 1fr);
  }

  ul {
    margin-bottom: 1.5em;
  }
}
.footer--newsletter {
  grid-column: 7 / 13;

  @include breakpoint($media-sm) {
    grid-column: 9 / 13;
  }

  .newsletterForm {
    position: relative;
    max-width: 28em;
  }
}
.footer--bottom {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 $main-padding-horizontal;

  @include breakpoint($media-sm) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.footer--bottom--colophon {
  padding-top: 3rem;
  @include breakpoint($media-sm) {
    padding-top: 0;
    text-align: right;
  }
}