.checkout {
  padding: 7.5rem $main-padding-horizontal;

  h1 {margin-bottom: 1em;}
}
.checkout--content {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 5rem;
  row-gap: 10rem;
  padding-top: 4rem;
  border-top: 1px solid var(--c-gray-8);
}
.checkout--main {
  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: 13;
  font-size: 2rem;

  @include breakpoint($media-sm) {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 8;
  }
  @include breakpoint($media-md) {
    grid-column-start: 1;
    grid-column-end: 7;
  }
}
.checkout--cart {
  grid-row-start: 1;
  grid-column-start: 1;
  grid-column-end: 13;
  font-size: 2rem;

  @include breakpoint($media-sm) {
    grid-row-start: 1;
    grid-column-start: 8;
  }
  @include breakpoint($media-lg) {
    grid-column-end: 12;
  }
}
.checkout--section {
  border-bottom: 1px solid var(--c-gray-9);
  padding-bottom: 3rem;
  margin-bottom: 3rem;

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }
}
.checkout--section--active, .checkout--section--previous {
  h3 {
    margin-bottom: .5em;
  }
}

.checkout {
  .signInUpForm {
    margin-bottom: 2rem;
  }
}

// payment method
.cardForm--cardNumbersRow {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  column-gap: 2rem;
}
.checkout--currentPaymentMethod--existingBrand {
  text-transform: capitalize;
}

// licensee
.checkout--licensee {
  .radioButtons {
    margin-bottom: 4rem;
  }
  .textInput {
    margin-bottom: 4rem;
  }
}

// Cart
.cart {
  box-sizing: border-box;

  .td-cost, .td-totalHeader, .td-remove {
    text-align: right;
  }
  .td-remove {
    button {
      font-weight: 200;
      color: var(--c-dark);
      text-decoration: none;

      &:hover {
        opacity: .6;
      }
    }
    font-size: 5rem;
    line-height: 1em;
    position: relative;
    top: -13px;

  }
}
.cart--title {
  margin-bottom: .8em;
}
.cart--licenseOptions {
  margin-bottom: 5rem;
}
.cart--packageName {
}
.cart--items {
  font-size: 2rem;
}
.cart--licenseSummary {
  font-size: 1.8rem;
  font-style: italic;
}
