.orderPage {
  main {
    padding: 6rem $main-padding-horizontal;
  }
  td {
    vertical-align: top;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
.orderPage--titleRow {
  display: grid;
  grid-template-columns: 6fr 1fr;
}
.orderPage--infoSection {
  margin-bottom: 4rem;

  @include breakpoint($media-sm) {
    margin-bottom: 2rem;
  }
}
.orderPage--customerInfo {
  margin-bottom: 5rem;
}
.orderPage--idSection {
  margin-bottom: 10rem;
}
.td-richSummary {
  a {
    color: var(--c-dark);
  }
}
.td-cost {
  text-align: right;
}
.orderPage--info {
  padding-top: 2rem;
  margin-bottom: 6rem;
  // font-size: 2.4rem;
  // border: 1px solid var(--c-gray-8);
  // background-color: var(--c-gray-10);
  // border-radius: 3px;
  // padding: 4rem;
  display: grid;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @include breakpoint($media-sm) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.orderPage--vectroInfo {
  
  // display: grid;
  // align-items: center;
  // justify-content: center;
  // text-align: center;
}
.orderPage--vectroInfoInnerWrap {
  border: 1px solid var(--c-gray-8);
  border-radius: 3px;
  padding: 3.2rem 3.5rem;
}
.orderPage--refundTotals {
  font-size: 2.8rem;
  font-weight: normal;
  font-style: italic;
}
