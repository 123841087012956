$font-main: 'VCTR Mono Web';
$fonts-path: 'https://assets.vectrotype.com/fonts/';
// $fonts-path: 'http://localhost:8000/public/fonts/';

@function rgbFromHex($hex) {
  @return red($hex), green($hex), blue($hex);
}

// scss color vars just for setting up. use css var(--yourcolor) instead

$c-dark: #160F21;
$c-light: #fdfdfd;
$c-red: #f1477d;

$c-light-peach: #f7efef;
$c-peach: #F4DAD9;
$c-cream: #F3BF8E;
$c-purple: #6749ED;
$c-blue: #4B47E3;
$c-dark-purple-1: #46305F;
$c-dark-purple-2: #271B35;
$c-dark-purple-3: #160F21;
$c-green: #7ECF8C;

$c-yellow: #FFF500;
$c-gray-theme: #eee;
// $c-blue: #89AAFF;
$c-bright--blue: #3B44AC;

@mixin defineGrays() {
  --c-gray-0: rgba(var(--c-dark), 1);
  --c-gray-1: rgba(var(--c-dark-rgb), 0.9);
  --c-gray-2: rgba(var(--c-dark-rgb), 0.8);
  --c-gray-3: rgba(var(--c-dark-rgb), 0.7);
  --c-gray-4: rgba(var(--c-dark-rgb), 0.6);
  --c-gray-5: rgba(var(--c-dark-rgb), 0.5);
  --c-gray-6: rgba(var(--c-dark-rgb), 0.4);
  --c-gray-7: rgba(var(--c-dark-rgb), 0.3);
  --c-gray-8: rgba(var(--c-dark-rgb), 0.16);
  --c-gray-9: rgba(var(--c-dark-rgb), 0.08);
  --c-gray-10: rgba(var(--c-dark-rgb), 0.05);

  --c-inverse-gray-0: var(--c-light);
  --c-inverse-gray-1: rgba(var(--c-light-rgb), 0.9);
  --c-inverse-gray-2: rgba(var(--c-light-rgb), 0.8);
  --c-inverse-gray-3: rgba(var(--c-light-rgb), 0.7);
  --c-inverse-gray-4: rgba(var(--c-light-rgb), 0.6);
  --c-inverse-gray-5: rgba(var(--c-light-rgb), 0.5);
  --c-inverse-gray-6: rgba(var(--c-light-rgb), 0.4);
  --c-inverse-gray-7: rgba(var(--c-light-rgb), 0.3);
  --c-inverse-gray-8: rgba(var(--c-light-rgb), 0.16);
  --c-inverse-gray-9: rgba(var(--c-light-rgb), 0.08);
  --c-inverse-gray-10: rgba(var(--c-light-rgb), 0.05);
}

:root {
  --c-dark: #{$c-dark};
  --c-dark-rgb: #{rgbFromHex($c-dark)};
  --c-dark-perma: #{$c-dark};
  --c-dark-rgb-perma: #{rgbFromHex($c-dark)};
  --c-light: #{$c-light};
  --c-light-rgb: #{rgbFromHex($c-light)};
  --c-light-perma: #{$c-light};
  --c-light-rgb-perma: #{rgbFromHex($c-light)};
  --c-red: #{$c-red};
  --c-red-rgb: #{rgbFromHex($c-red)};
  --c-green: #{$c-green};
  --c-green-rgb: #{rgbFromHex($c-green)};
  --c-yellow: #{$c-yellow};
  --c-yellow-rgb: #{rgbFromHex($c-yellow)};
  --c-purple: #{$c-purple};
  --c-purple-rgb: #{rgbFromHex($c-purple)};
  --c-dark-purple-1: #{$c-dark-purple-1};
  --c-dark-purple-1-rgb: #{rgbFromHex($c-dark-purple-1)};
  --c-dark-purple-2: #{$c-dark-purple-2};
  --c-dark-purple-2-rgb: #{rgbFromHex($c-dark-purple-2)};
  --c-dark-purple-3: #{$c-dark-purple-3};
  --c-dark-purple-3-rgb: #{rgbFromHex($c-dark-purple-3)};
  --c-cyan: #00FFFF;
  --c-blue: #{$c-blue};
  --c-peach: #{$c-peach};
  --c-peach-rgb: #{rgbFromHex($c-peach)};
  --c-light-peach: #{$c-light-peach};
  --c-light-peach-rgb: #{rgbFromHex($c-light-peach)};
  --c-cream: #{$c-cream};
  --c-cream-rgb: #{rgbFromHex($c-cream)};

  @include defineGrays();

  --c-bg-highlight: var(--c-gray-9);
  --c-bg-highlight-rgb: var(--c-gray-9);

  --c-checkbox-selected: var(--c-blue);
  --c-checkbox-selected-rgb: var(--c-blue-rgb);
}

$main-padding-vertical: 14rem;
$main-padding-horizontal: 2.3vw;
$main-padding: $main-padding-vertical $main-padding-horizontal;

$media-xs: 600px;
$media-sm: 800px;
$media-md: 1000px;
$media-lg: 1440px;
$media-xxl: 1900px;


