html {
  font-size: 34.5%;
  font-size: 1.15vw;

  @include breakpoint($media-xs) {
    font-size: 1vw;
  }
  @include breakpoint($media-sm) {
    font-size: .8vw;
  }
  @include breakpoint($media-md) {
    font-size: .68vw;
  }


  @media print {
    font-size: 5px;
  }
}
body {
  font-size: 2.4rem;
  line-height: 1.4;
  color: var(--c-dark);
  font-family: $font-main;
}
p {
  margin: 0 0 .6em;  
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
h1 {
  font-size: 7.8rem;
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: .3em;
  line-height: 1em;

  a {
    text-decoration: none;
    font-weight: normal;
  }
}
h2 {
  font-size: 4rem;
  font-weight: normal;
  line-height: 1em;
  margin-bottom: .4em;

  a {
    text-decoration: none;
    font-weight: normal;
  }
}
h3 {
  font-size: 2.8rem;
  font-weight: 500;
  text-transform: uppercase;

  a {
    text-decoration: none;
  }
}
h4 {
  font-size: 2.5rem;
  margin-bottom: .2em;
  font-weight: normal;
  text-transform: uppercase;

  @include breakpoint($media-sm) {
    font-size: 1.8rem;
  }

  a {
    text-decoration: none;
    font-weight: normal;
  }
}
h5 {
  font-size: 2rem;
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: .8em;

  @include breakpoint($media-sm) {
    font-size: 1.6rem;
  }
}
h6 {
  font-size: 2.5rem;
  font-weight: normal;

  a {
    text-decoration: none;
    font-weight: normal;
  }
}
a, .btn--normalLink {
  display: inline;
  transition: transform .2s ease-in-out;
  // font-weight: 500;
  color: var(--c-dark);
  text-decoration: underline;
  
  &:hover {
    text-decoration: none;
  }
  &:active {
    opacity: .6;
    text-decoration: underline;
  }

  &.ignoreScale {
    &, &:hover, &:active {
      transform: none;
    }
  }
}
ul, li {
  margin: 0;
  padding: 0;
}
ul {

  &.asteriskList {
    li {
      position: relative;
      list-style: none;
      padding-left: 1em;
    }
    li:before {
      content: "*";
      position: absolute;
      left: 0;
      top: .08em;
    }
  }
}
code {
  display: inline-block;
  padding: 0 .2em;
  background-color: var(--c-gray-9);
  border-radius: 2px;
  font-family: $font-main;
}
figcaption {
  font-size: 1.6rem;
  font-style: italic;
  color: var(--c-gray-3);
}
em {
  font-style: italic;
}
strong {
  font-weight: 600;
}
details {
  margin-bottom: 1.5em;
  padding-right: 2em;
  padding-bottom: 1.5em;
  padding-left: 1em;
  border-bottom: 1px solid var(--c-gray-9);

  &:last-child {
    border-bottom: 0;
  }
}
summary {
  position: relative;
  cursor: pointer;
  list-style: none;
  margin-left: -1em;
  padding-right: 2em;

  &:hover {
    font-weight: 500;

    &:before {
      font-weight: 300;
    }
  }

  &::-webkit-details-marker {
    display: none;
  }
  &:before {
    content: "↓";
    position: absolute;
    right: 0;
    top: -.1em;
    color: var(--c-dark);
    font-size: 2em;
    line-height: 1em;
    font-weight: 200;

    details[open] & {
      content: "↑";
    }
  }
  
  details[open] & {
    margin-bottom: .5em;
    font-weight: 500;
  }
}
.helpBtn {
  position: relative;
  top: -.01em;
  text-decoration: none;
  display: inline-block;
  border: 1px solid var(--c-dark);
  border-radius: 2em;
  font-size: .8em;
  width: 1.2em;
  height: 1.2em;
  text-align: center;
}