.accountOrders--orderItem {
  margin-bottom: .5em;

  &:last-child {
    margin-bottom: 0;
  }
}
.accountOrders--ordersList {
  .td-total_cost_in_dollars {
    text-align: right;
  }
  .td-actions {
    position: relative;
    top: -.5em;
  }

  tbody :last-child {
    border-bottom: none;
  }
}
.accountOrders--orderItem--meta {
  font-size: 1.8rem;
  margin-bottom: .5em;

}
.accountAccount, .accountPassword {
  form {
    @include breakpoint($media-sm) {
      max-width: 60rem;
    }
  }
}