.selectInputPlus--inputWrapper {
  position: relative;
  .formInput {
    margin-bottom: 0;
  }
}
.selectInputPlus--options {
  position: absolute;
  top: 7.1rem;
  left: 0;
  width: 20em;
  background-color: var(--c-light);
  border-radius: 2px;
  margin: 0;
  padding: 0;
  z-index: 10;
  box-shadow: 0 2px 2px 0 rgba(var(--c-dark-rgb),0.14), 0 3px 1px -2px rgba(var(--c-dark-rgb),0.12), 0 1px 5px 0 rgba(var(--c-dark-rgb),0.20);
  max-height: 10em;
  overflow: auto;
}
.selectInputPlus--option {
  margin: 0;
  padding: .5em 1em;
  list-style-type: none;
  border-bottom: 1px solid var(--c-gray-10);
  &:hover {
    cursor: pointer;
    background-color: var(--c-gray-10);
  }
}
.selectInputPlus--option--highlighted {
  background-color: var(--c-gray-10);
}