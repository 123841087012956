
// tester
.testerPage {
  padding: 2rem 0 12rem;

  &:hover {
    
  }
}
.testerPage--stickyControls {
  position: fixed;
  pointer-events: none;
  opacity: 0;
  transition: opacity .5s;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--c-light);
  z-index: 1;
  padding: 1rem $main-padding-horizontal;
  border-bottom: 1px solid var(--c-gray-8);

  &.sticky--active {
    opacity: 1;
    pointer-events: initial;
  }
}
.testerPage--controls {
  display: grid;
  grid-template-columns: 1fr 40rem;
  column-gap: 4rem;
  padding: 2.5rem $main-padding-horizontal 4.2rem;

  @include breakpoint($media-sm) {
    grid-template-columns: 1fr 50rem;
  }

  .testerPage--stickyControls & {
    padding-bottom: 0;
    padding-top: 0;
  }

  .textInput {
    margin-right: 0;
    margin-bottom: 0;

    input {
      font-size: 2.9rem;
      background-color: var(--c-gray-9);
      border-color: transparent;

      &:focus {
        border-color: var(--c-gray-8);
      }
      .testerPage--stickyControls & {
        font-size: 1.8rem;
      }
    }
  }
  .rangeInput {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;

    label {
      display: inline-block;
      margin-right: 2rem;
      margin-bottom: 0;
      white-space: nowrap;

      .testerPage--stickyControls & {
        font-size: 1.8rem;
      }
    }
    input {
      min-width: 28rem;
    }
  }
}
.testerPage--fontStyle {
  position: relative;
  display: block;
  border-top: 1px solid var(--c-gray-8);
  padding-top: 1rem;
  padding-bottom: 2rem;
  text-decoration: none;
  min-height: 16.5rem;
  
  &.lazy--viewed, &.lazy--visible, &.lazy--above {
    min-height: auto;
  }
  
  h6 {
    margin-bottom: 0;
  }
  &:hover, &:active {
    background-color: var(--c-bg-highlight-rgb);
    text-decoration: none;
  }
}
.testerPage--fontStyle--title {
  margin: 0 $main-padding-horizontal 1.25rem;
}
.testerPage--fontStyle--textWrapper {
  position: relative;
  display: block;
  transform-origin: left center;

  .fontSize--isThrottling & {
    opacity: .2;
  }
}
.testerPage--fontStyle--text, .testerPage--fontStyle--text--layer2, .testerPage--fontStyle--img {
  display: none;
  animation: fade-in .5s;

  .lazy--viewed &, .lazy--visible &, .lazy--above & {
    display: block;
  }
}
.testerPage--fontStyle--text, .testerPage--fontStyle--img {
  position: relative;
  white-space: nowrap;
  padding-left: $main-padding-horizontal;
  padding-right: $main-padding-horizontal;
}
.testerPage--fontStyle--img {
  img {
    height: 1em;
    max-width: none;
  }
}
.testerPage--fontStyle--text--layer2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  white-space: nowrap;
  padding-left: $main-padding-horizontal;
  padding-right: $main-padding-horizontal;
}