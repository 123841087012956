.fontTester {
  position: relative;
  border-top: 1px solid var(--c-gray-8);
  padding-top: 8rem;
  padding-bottom: 4rem;
  min-height: 5vw;

  &:last-child {
    border-bottom: 1px solid var(--c-gray-8);
  }
  .fontTester--variable & {
    padding-bottom: 2rem;
  }
}
.fontTester--inputsWrapper {
  @include breakpoint($media-sm) {
    .fontTester--numInputs-2 & {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2em;
      padding: 1em 0;
    }
  }
}
.fontTester--inputWrapper {
  position: relative;
  display: none;
  &:first-child {
    display: block;
  }
  @include breakpoint($media-sm) {
    display: block;
  }
}
.fontTester--input, .fontTester--inputLayer2 {
  position: relative;
  z-index: 1;
  // this is to address Variable rendering bugs
  width: 100%;
  padding-left: 2em;
  padding-right: 2em;
  margin-left: -2em;
  margin-right: -2em;
}
.fontTester--numInputs-2 .fontTester--input--contentEditable {
  font-size: 2em;

  @include breakpoint($media-sm) {
    font-size: 1em;
  }
}
.fontTester--input--contentEditable:focus {
  outline: none;
  border: none;
}
.fontTester--inputLayer2 {
  position: absolute;
  left: 0;
  right: 0;
  pointer-events: none;
}
.fontTester--header {
  position: absolute;
  top: .7rem;
  left: 0;
  right: 0;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}
.fontTester--controls {
  display: none;
  justify-content: space-between;
  z-index: 5;

  .fontTester:hover &, .fontTester--focused & {
    display: flex;
  }
  .formInput {
    margin-left: 2rem;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    margin-bottom: 0;

    &:last-child {
      display: none;
      @include breakpoint($media-sm) {
        display: flex; 
       }
    }
  }
  .rangeInput--small input[type=range] {
    width: 25rem;
  }
  label {
    display: inline-block;
    margin-right: 1rem;
    font-size: 2rem;
    margin-bottom: 0;
    white-space: nowrap;
    font-style: italic;
  }
}
.fontTester--tooltip {
  display: none;
  background-color: var(--c-dark);
  position: absolute;
  top: 0;
  right: 0;
  padding: .2em .5em;
  font-size: 1.4rem;
  color: var(--c-light);

  .fontTester--inputWrapper:hover & {
    .fontTester--focused & {
      display: none;
    }
  }
}
.fontTester--variable {
  padding-bottom: 5rem;

  &:last-child {
    border-bottom: 1px solid var(--c-gray-8);
  }

  .fontTester--input {
    padding-top: .05em;
    padding-bottom: .1em;
  }
}
.fontTester--variableControls--axes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 4rem;
  row-gap: 3rem;

  .fontTester--variable--numAxes-1 & {
    display: block;
  }
}
.fontTester--variableControls {
  position: relative;
  z-index: 2;

  .rangeInput {
    margin-bottom: 0;
    width: 100%;
  }
  input {
    margin: 0;
    flex-grow: 1;
    top: 0;
  }
  label {
    border-radius: 100em;
    font-size: 2rem;
    padding-left: 1rem;
  }
  .rangeInput--inputWrapper {
    display: flex;
    background-color: var(--c-gray-9);
    border-radius: 100em;
    padding: .1rem 1.5rem 0 1.2rem;
    justify-content: center;
  }
}
.fontTester--variableAxis {
}
.fontTester--variableAxis--value {
  color: var(--c-gray-6);
}
.fontTester--variableAxis--extraControlsBefore {
  position: relative;
  top: .05em;
  white-space: nowrap;
  margin-right: 1rem;
}
.fontTester--variableAxis--extraControlsAfter {
  position: relative;
  top: .05em;
  white-space: nowrap;
  margin-left: 1.1rem;
}
.fontTester--variableAxis--resetBtn {
  font-size: .8em;
  svg {
    fill: var(--c-gray-6);

    .input--modified & {
      fill: var(--c-dark);
    }
  }



}
.fontTester--variableAxis--animationBtn {
  right: 2.5rem;
  top: 0;
  font-size: .8em;
}
