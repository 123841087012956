.newsletterForm {
  label, .formErrors, .newsletterForm--successful {
    padding-left: .3em;
  }
  label {
    font-size: 2rem;
  }
  input {
    background-color: var(--c-light);
    border: 1px solid var(--c-light);
    border-radius: 10em;

    &::placeholder {
      color: var(--c-gray-5);
    }
    &:focus{
      outline: none;
    }
  }
  .newsletterForm--successful {
    padding-top: 1em;
    text-align: left;
    font-style: italic;
  }
}