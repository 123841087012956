@keyframes fade-in {
  0% {opacity:0;}
  100% {opacity:1;}
}
@keyframes fade-in-with-delay {
  0% {opacity:0;}
  50% {opacity:0;}
  100% {opacity:1;}
}
@keyframes marquee {
 0%   { 
  transform: translateX(0%);     
 }
 100% { 
  transform: translateX(-100vw); 
 }
}
@keyframes horizontal-marquee {
 0%   { 
  transform: translateX(-110vw);     
 }
 100% { 
  transform: translateX(0vw); 
 }
}
@keyframes vertical-marquee {
  0%   { 
   transform: translateY(-110vh);     
  }
  100% { 
   transform: translateY(0vh); 
  }
}

@keyframes kablammo {
  0%   { 
    font-variation-settings: 'MORF' 0;     
  }
  100% { 
    font-variation-settings: 'MORF' 60;
  }
}
@keyframes whoa {
  0%   { 
    font-variation-settings: 'HRZN' 420, 'VERT' 360, 'ROTA' -8, 'ZOOM' 0;     
  }
  25% {
    font-variation-settings: 'HRZN' 1000, 'VERT' 1000, 'ROTA' 45, 'ZOOM' 0;
  }
  50% {
    font-variation-settings: 'HRZN' 1000, 'VERT' 0, 'ROTA' 0, 'ZOOM' 0;
  }
  75% {
    font-variation-settings: 'HRZN' -1000, 'VERT' 0, 'ROTA' 0, 'ZOOM' 0;
  }
  100% { 
    font-variation-settings: 'HRZN' -500, 'VERT' -1000, 'ROTA' -15, 'ZOOM' -1000;
  }
}
@keyframes wildberry {
  0%   { 
    font-variation-settings: 'wght' 900;     
  }
  100% { 
    font-variation-settings: 'wght' 100;
  }
}
@keyframes kicker {
  0%   { 
    font-variation-settings: 'LAY1' 1000, 'LAY2' 800, 'LAY3' 1;
  }
  33%   { 
    font-variation-settings: 'LAY1' 1000, 'LAY2' 100, 'LAY3' 1;
  }
  66%   { 
    font-variation-settings: 'LAY1' 1000, 'LAY2' 800, 'LAY3' 1;
  }
  100%   { 
    font-variation-settings: 'LAY1' 1000, 'LAY2' 800, 'LAY3' 600;
  }
}