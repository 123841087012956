.blogIndexPage, .blogPage {
  padding: 7.5rem $main-padding-horizontal 7.5rem;
  font-size: 2.5rem;
}

// index page
.blogIndexPage {
}
.blogIndex--posts {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 2rem;
}
.blogIndex--thumbnail {
  grid-column: 2 / 12;
  margin-bottom: 8rem;
  padding-bottom: 3rem;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  column-gap: 2rem;

  @include breakpoint($media-sm) {
    grid-column: 1 / 11;
    margin-bottom: 4rem;
  }
  
  a {
    font-weight: normal;

    &:active {
      text-decoration: none;
    }
  }
  &:last-child {
    border-bottom: none;
  }

  h2 {
    font-size: 5rem;
    line-height: 1.1em;
    margin-bottom: 1em;
    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
.blogIndex--thumbnail--meta {
  grid-column: 1 / 11;
  font-size: 2.3rem;
  
  @include breakpoint($media-sm) {
    grid-column: 1 / 3;
  }
}
.blogIndex--thumbnail--main {
  grid-column: 1 / 11;

  @include breakpoint($media-sm) {
    grid-column: 3 / 11;
  }
}
.blogIndex--thumbnail--coverImg {
  margin-bottom: 1rem;
}
// article page

@mixin blog-page-grid () {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 2rem;
}
.blogPage {
  h1 {
    grid-column: 2 / 12;
    text-transform: none;
    font-size: 5.5rem;
    // font-weight: 500;

    @include breakpoint($media-sm) {
      grid-column: 3 / 11;
    }
  }
  img {
    border: 1px solid var(--c-gray-8);
  }
  &.blogPost--3 {
    img {
      border: none;
    }
  }
}
.blogPage--main {
  @include blog-page-grid;
}
.blogPage--credits {
  grid-column: 2 / 12;
  margin-bottom: 5rem;
  font-size: 2rem;

  @include breakpoint($media-sm) {
    grid-column: 3 / 11;
  }
}
.blogPage--date {
  display: inline-block;
  
}
.blogPage--author {
  display: inline-block;
  span {
    font-style: italic;
  }
}
.blogPage--coverImg {
  grid-column: 1 / 13;
  margin-bottom: 6rem;
  width: 100%;

  a, img {
    display: block;
    width: 100%;
  }
  img {
    border: none;
  }
  @include breakpoint($media-sm) {
    grid-column: 2 / 12;
  }
}
.blogPage--content {
  @include blog-page-grid();
  grid-column: 1 / 13;
  > * {
    grid-column: 2 / 12;
    
    @include breakpoint($media-sm) {
      grid-column: 3 / 11;
    }
  }
  .md--image--section {
    grid-column: 1 / 13;
    @include breakpoint($media-sm) {
      grid-column: 2 / 12;
    }
  }
  h2 {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    font-weight: 500;

    &:first-child {
      margin-top: none;
    }
  }
  .md--image--section + h2 {
    margin-top: 0rem;
  }
}