.licensePicker {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  h4 {

  }
}
.licensePicker--bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(var(--c-dark-rgb), .4);
  cursor: pointer;
}
.licensePicker--content {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background-color: var(--c-light);
  border-left: 1px solid var(--c-gray-9);
  width: 75vw;
  min-width: 40rem;
  max-width: 100vw;
  display: grid;
  grid-template-rows: 1fr 16rem;

  &::after {
    pointer-events: none;
    content: '';
    position: absolute;
    bottom: 16rem;
    left: 0;
    right: 0;
    height: 2rem;
    background: linear-gradient(0deg, rgba(var(--c-dark-rgb),.2) 0%, rgba(var(--c-dark-rgb),0) 100%);
  }

  @include breakpoint($media-sm) {
    width: 32vw;
    min-width: 40rem;
    max-width: 100vw;
  }
}
.licensePicker--main {
  position: relative;
  overflow: auto;
}
.licensePicker--mainInner {
  position: relative;
  box-sizing: border-box;
  padding: 4.5rem 3rem 5rem;
  min-height: 100%;
  // background-color: red;
  
  &::after {
    pointer-events: none;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4rem;
    background: linear-gradient(0deg, rgba(var(--c-light-rgb),1) 0%, rgba(var(--c-light-rgb), 1) 20%, rgba(var(--c-light-rgb), 0) 100%);
    // background: linear-gradient(0deg, rgba(var(--c-purple-rgb),1) 0%, rgba(var(--c-purple-rgb), 0) 100%);
    z-index: 1;
  }
}
.licensePicker--closeBtn {
  position: absolute;
  top: 0rem;
  right: 2rem;
  text-decoration: none;
  font-size: 6rem;
  font-weight: 300;
  display: block;

  &:hover, &:active {
    text-decoration: none;
  }
}
.licensePicker--intro {
  margin-bottom: 5rem;

  p {
    @include breakpoint($media-sm) {
      font-size: 1.6rem;
    }
  }
}
.licensePicker--tables {
  margin-bottom: 5.5rem;
}
.licensePicker--table {
  cursor: pointer;
  border: 1px solid var(--c-gray-8);
  border-radius: .8em;
  padding: 2rem 2.5rem 2.5rem;
  margin-bottom: 1.5rem;

  &:hover {
    background-color: var(--c-gray-1)0;
  }

  .formInput {
    margin-bottom: 0;
  }
  .selectInput {
    font-size: 1.8rem;
  }
}
.licensePicker--table--active {
  &, &:hover {
    border-color: var(--c-gray-5);
    background-color: var(--c-bg-highlight);
  }
}
.licensePicker--table--label {
  font-size: 2.5rem;
  margin-bottom: .3em;

  @include breakpoint($media-sm) {
    font-size: 2rem;
  }
}
.licensePicker--table--helperText {
  font-size: 2rem;
  margin-bottom: 1em;

  @include breakpoint($media-sm) {
    font-size: 1.4rem
  }
}
.licensePicker--table--hideHelperText {
  padding-bottom: 1.8rem;

  .licensePicker--table--label {
    margin-bottom: 0;
  }
}
.licensePicker--packages {

  .radioButtons, .checkboxInput {
    font-size: 2.5rem;
    margin-bottom: 0;

    @include breakpoint($media-sm) {
      font-size: 2rem;
    }
  }
}
.licensePicker--packages--individualOptions {
  padding-left: 3rem;
}
.licensePicker--footer {
  padding: 2rem 3rem;
  background-color: var(--c-light);
  border-top: 1px solid var(--c-gray-8);

  .btn {
    font-size: 2rem;
    margin: 0;
  }
}
.licensePicker--footer--totals {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 2rem;
  font-size: 2.5rem;

  @include breakpoint($media-sm) {
    font-size: 2rem;
  }
}
.licensePicker--package--onSale {
  position: relative;
  &::after {
    content: 'ON SALE!';
    position: relative;
    display: inline-block;
    font-size: 1.2rem;
    background-color: var(--c-purple);
    color: var(--c-light);
    top: -.3rem;
    right: -1rem;
    padding: .2em .65em .2em .75em;
    border-radius: 1em;
  }
  // color: var(--c-red);
}