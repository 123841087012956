.infoPage {
  padding: 7.5rem $main-padding-horizontal 7.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 6fr;
  font-size: 2.8rem;

  a {
    display: inline;
  }
  .newsletterForm {
    position: relative;
    left: -.2em;
    font-size: 2rem;
    max-width: 20em;
    padding-bottom: .75rem;
    
    .form--inlineInputButton {
      padding-top: .5em;
    }
    input {
      background-color: var(--c-gray-10);
      border: 1px solid var(--c-gray-10);
      &:focus{
        border-color: var(--c-gray-7);
      }
    }
  }
}
.infoPage--main {
  grid-column: 1 / 4;
  order: 0;
  margin-bottom: 6rem;

  h2 {
    line-height: 1.2em;
    margin-bottom: 1em;
  }
  h3 {
    margin-top: 4rem;
  }

  @include breakpoint($media-sm) {
    grid-column: 2 / 4;
    order: 1;
  }
}
.infoPage--overview {
  grid-column: 1 / 4;
  order: 1;
  
  @include breakpoint($media-sm) {
    order: 0;
    grid-column: 1 / 1;
    padding-right: 8rem;
  }
}
.infoPage--overviewSection {
  margin-bottom: 4rem;
}
