@keyframes home--scroller {
  0% {
    left: 15%;
  }
  100% {
    left: -485%;
  }
}
.home--scroller {
  font-size: 8.2rem;
  height: 1.7em;
  overflow: hidden;
  width: 100%;
  position: relative;
  animation: fade-in-with-delay 1.5s;

  a {
    &:hover, &:active {
      transform: none;
    }
  }
}
.home--scroller--links {
  position: absolute;
  white-space: nowrap;
  animation: home--scroller 60s forwards infinite linear;
}
.home--scroller--message {
  white-space: nowrap;
  padding: .15em 0;
  text-decoration: underline;
  text-decoration-thickness: .05em;
  text-underline-offset: .04em;
  font-weight: 300;
  display: inline-block;
  margin-right: 2em;
}
// flashy
.home--cards {
  padding: 0 $main-padding-horizontal;
}
.home--cards--font {
  padding-top: 0rem;
  padding-bottom: 4rem;
  border-bottom: 1px solid var(--c-gray-8);

  &:last-child {
    border-bottom: 0;
  }
}
.home--cards--lazyPlaceholder {
  display: block;
  height: 40vw;

  .lazy--viewed &, .lazy--visible &, .lazy--above & {
    display: none;
  }
}
.home--cards--name {
  font-size: 4rem;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: normal;
}
.home--cards--kablammo {
}
.home--cards--whoa {
  padding-top: 0rem;
}
.home--cards--kicker {
  padding-top: 8rem;
}
.home--cards--fontExample {
  position: relative;
  text-decoration: none;
  color: var(--c-dark);
  display: none;
  animation: fade-in .5s;

  .lazy--viewed &, .lazy--visible &, .lazy--above & {
    display: block;
  }

  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
    opacity: 1;
  }
  .comingSoonBadge {
    position: absolute;
    top: 13%;
    right: 10%;
    width: 12%;
    transform: rotate(5deg);
  }
}
.home--cards--fontExample--content {
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  @keyframes home-kablammo {
    0% {
      font-variation-settings: 'MORF' 0;
    }
    100% {
      font-variation-settings: 'MORF' 60;
    }
  }
  @keyframes home-kablammo-2 {
    0% {
      font-variation-settings: 'MORF' 0;
    }
    33% {
      font-variation-settings: 'MORF' 20;
    }
    66% {
      font-variation-settings: 'MORF' 40;
    }
    100% {
      font-variation-settings: 'MORF' 60;
    }
  }
  .home--cards--kablammo & {
    font-family: 'Kablammo Web';
    font-size: 30vw;
    line-height: .85em;
    padding-top: 3rem;
    padding-bottom: 11rem;
  }
  .lazy--visible.home--cards--kablammo & {
    animation: home-kablammo-2 5s ease-in-out infinite alternate;
  }
  .home--cards--vctr-mono & {
    font-size: 3.9vw;
    font-family: $font-main;
    line-height: .98em;
    padding-top: 6.9rem;
    padding-bottom: 9.5rem;

    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 2rem;
      row-gap: 3.6rem;
    }
    li {
      margin: 0;
    }
    li:nth-child(1) {
      font-weight: 200;
    }
    li:nth-child(2) {
      font-weight: 300;
    }
    li:nth-child(3) {
      font-weight: 400;
    }
    li:nth-child(4) {
      font-weight: 400;
      font-style: italic;
    }
    li:nth-child(5) {
      font-weight: 500;
    }
    li:nth-child(6) {
      font-weight: 600;
    }
    li:nth-child(7) {
      font-weight: 800;
    }
  }
  @keyframes home-whoa {
    0% {
      font-variation-settings: 'VERT' 1000, 'HRZN' 0, 'ROTA' 0, 'ZOOM' 0;
    }
    20% {
      font-variation-settings: 'VERT' 0, 'HRZN' 1000, 'ROTA' 0, 'ZOOM' 0;
    }
    40% {
      font-variation-settings: 'VERT' 0, 'HRZN' 0, 'ROTA' 0, 'ZOOM' 0;
    }
    60% {
      font-variation-settings: 'VERT' 400, 'HRZN' 400, 'ROTA' -25, 'ZOOM' -1000;
    }
    80% {
      font-variation-settings: 'VERT' 500, 'HRZN' 100, 'ROTA' -35, 'ZOOM' 500;
    }
    100% {
      font-variation-settings: 'VERT' 1000, 'HRZN' 0, 'ROTA' 0, 'ZOOM' 0;
    }
  }
  @keyframes home-whoa-ff {
    0% {
      font-variation-settings: 'VERT' 550, 'HRZN' 0, 'ROTA' 0, 'ZOOM' 0;
    }
    20% {
      font-variation-settings: 'VERT' 0, 'HRZN' 1000, 'ROTA' 0, 'ZOOM' 0;
    }
    40% {
      font-variation-settings: 'VERT' 0, 'HRZN' 0, 'ROTA' 0, 'ZOOM' 0;
    }
    60% {
      font-variation-settings: 'VERT' 400, 'HRZN' 400, 'ROTA' -25, 'ZOOM' -1000;
    }
    80% {
      font-variation-settings: 'VERT' 500, 'HRZN' 100, 'ROTA' -35, 'ZOOM' 500;
    }
    100% {
      font-variation-settings: 'VERT' 550, 'HRZN' 0, 'ROTA' 0, 'ZOOM' 0;
    }
  }
  .home--cards--whoa & {
    height: 55vw;

    > div {
      pointer-events: none;
      position: absolute;
      top: .2em;
      left: -2em;
      right: -2em;
      font-family: 'WHOA Spine Web';
      font-size: 50vw;
      line-height: .8em;
      overflow: visible;
      font-variation-settings: 'VERT' 550;
      padding-left: 2em;
      padding-right: 2em;
      text-align: center;
    }
  }
  .home--cards--whoa.lazy--visible & {
    > div {
      animation: home-whoa 5s linear infinite;

      .browser-firefox & {
        animation: home-whoa-ff 5s linear infinite;
      }

    }
  }
  .home--cards--analog & {
    display: flex;
    position: relative;
    left: -2.2em;
    font-family: 'Analog Web';
    font-size: 4.8rem;
    line-height: 1.1em;
    justify-content: space-between;
    padding-top: 10.5rem;
    padding-bottom: 10rem;

    @include breakpoint($media-sm) {
      font-size: 7.7rem;
      padding-top: 20.5rem;
      padding-bottom: 20rem;
    }
    @include breakpoint($media-md) {
      font-size: 8.8rem;
    }

    ul {
      width: 49%;
      text-align: right;

      &:nth-child(2) {
        font-style: italic;
        text-align: left;
      }
    }

    li {
      white-space: nowrap;
    }

    li:nth-child(1) {
      font-weight: 200;
    }
    li:nth-child(2) {
      font-weight: 300;
    }
    li:nth-child(3) {
      font-weight: 400;
    }
    li:nth-child(4) {
      font-weight: 500;
    }
    li:nth-child(5) {
      font-weight: 600;
    }
  }
  .home--cards--analog & {
    position: relative;
    img {
      width: 100%;
    }
  }
  @keyframes home-kicker {
    0% {
      font-variation-settings: 'LAY1' 1000, 'LAY2' 1, 'LAY3' 1;
    }
    5% {
      font-variation-settings: 'LAY1' 1000, 'LAY2' 1, 'LAY3' 1;
    }
    50% {
      font-variation-settings: 'LAY1' 1000, 'LAY2' 650, 'LAY3' 1;

    }
    95% {
      font-variation-settings: 'LAY1' 1000, 'LAY2' 650, 'LAY3' 650;
    }
    100% {
      font-variation-settings: 'LAY1' 1000, 'LAY2' 650, 'LAY3' 650;
    }
  }
  .home--cards--kicker & {
    position: relative;
    height: 46vw;

    > div {
      position: absolute;
      top: -.05em;
      left: 0;
      right: 0;
      pointer-events: none;
      font-family: 'Kicker Variable Web';
      font-size: 40vw;
      line-height: 1em;
      text-align: center;
      font-variation-settings: 'LAY1' 1000, 'LAY2' 550, 'LAY3' 150;

    }
  }
  .home--cards--kicker.lazy--visible & {
    > div {
      animation: home-kicker 5s linear infinite alternate;
      animation-delay: -2.8s;
    }
  }
  .home--cards--wildberry & {
    font-family: 'Wildberry Variable Web';
    font-size: 9.6vw;
    line-height: 0.76em;
    text-align: center;
    padding-top: 12rem;
    padding-bottom: 11rem;

    li:nth-child(1) {
      font-variation-settings: 'wght' 900;
    }
    li:nth-child(2) {
      font-variation-settings: 'wght' 670;
    }
    li:nth-child(3) {
      font-variation-settings: 'wght' 470;
    }
    li:nth-child(4) {
      font-variation-settings: 'wght' 265;
    }
    li:nth-child(5) {
      font-variation-settings: 'wght' 100;
    }
  }
  .home--cards--chartwell & {
    position: relative;
    padding-top: 6.5rem;
    padding-bottom: 8.5rem;
    text-align: center;
    img {
      width: 100%;
    }
  }
  .home--cards--rainer & {
    font-family: 'Rainer Web';
    font-weight: 600;
    font-size: 35vw;
    line-height: .87em;
    padding-top: .02em;
    padding-bottom: .25em;

    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    > div:nth-child(1) {
      // first row
      div:nth-child(2) {
        font-weight: 100;
        padding-left: .3em;
      }
    }

    > div:nth-child(2) {
      // second row
      div:nth-child(1) {
        font-feature-settings: "ss01" 1;
      }
      div:nth-child(3), div:nth-child(4) {
        font-weight: 100;
      }
    }
  }
}
.home--cards--fontExample--content {
  pointer-events: none;
}
