.slideshow {
  position: relative;
}
.slideshow--slide {
  img {
    display: block;
    border-radius: 8rem;
    width: 100%;
    @include breakpoint($media-sm) {
      border-radius: 12.6rem;
    }

    // .page--Wildberry & {
      // border-radius: 0;
    // }
    
  }
}
.slideshow--navBtns {
  display: none;
  position: absolute;
  background-color: var(--c-light);
  border: 1px solid var(--c-dark);
  color: var(--c-dark);
  top: 50%;
  transform: translateY(-100%);
  width: 6.4rem;
  height: 6.4rem;
  font-size: 5rem;
  padding: 0;
  overflow: hidden;
  line-height: 1em;
  margin: 0;

  .slideshow:hover & {
    display: block;
  }

  &:hover {
    color: var(--c-dark);
    opacity: .9;
  }

  span {
    position: relative;
    top: .05em;
  }
}
.slideshow--prev {
  left: 3rem;
}
.slideshow--next {
  right: 3rem;
}
.slideshow--indicator {
  width: 1.6rem;
  height: 1.6rem;
  padding: 0;
  overflow: hidden;
  background-color: var(--c-light);
  margin: 0 .4rem;
}
.slideshow--indicator--active {
  background-color: var(--c-dark);
}
.slideshow--indicators {
  padding-top: 4rem;
  text-align: center;
}
