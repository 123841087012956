$c-placeholder-text: $c-dark-purple-3; 
$media-placeholder-sm: 600px;

.placeholder {
  position: fixed;
  background-color: $c-peach;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .threeDScreensaver { 
    position: absolute;
    background-color: transparent;
  }

  .newsletterForm {
    position: fixed;
    display: block;
    box-sizing: border-box;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 3rem 3rem;
    font-size: 12px;
    text-align: center;
    @include breakpoint($media-placeholder-sm) {
      font-size: 16px;
    }

    form {
      display: inline-block;
      width: 30em;
      max-width: 100%;
    }
    label {
      font-size: 1em;
      color: $c-placeholder-text;
    }
    input {
      background-color: transparent;
      border-color: $c-placeholder-text;

      &::placeholder {
        color: $c-placeholder-text;
      }
    }
    .btn {
      background-color: $c-placeholder-text;
      border-color: $c-placeholder-text;
      color: $c-peach;
    }
  }
  .form--inlineInputButton {
    .formInput {
      width: 70%;
    }
    .btn {
      width: 30%;
    }
  }
  
}
