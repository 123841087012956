// nav
.themeNav {
  transition: right .8s ease-in-out, opacity .5s linear;
  display: flex;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 5;
  align-items: center;
  opacity: 1;
}
.themeNav--collapsed {
  right: -11.2rem;

  // &.themeNav--homeActive {
    // right: -41rem;
  // }
}
.themeNav--hidden {
  opacity: 0;
  pointer-events: none;
}
.themeNav--toggleBtn {
  position: relative;
  z-index: 2;
  transition: left .8s ease-in-out, transform .2s ease-in-out, opacity .3s linear;
  background-color: var(--c-light-perma);
  box-shadow: 0 0 3px 3px rgba(var(--c-dark-rgb), .2);
  border-radius: 100em;
  width: 2rem;
  height: 3rem;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  opacity: 0;

  .themeNav:hover & {
    opacity: 1;
  }

  &:hover {
    transform: scale(1.2);
  }

  .themeNav--collapsed & {
    left: -2rem;
    opacity: 1;
  }

  svg {
    width: 4rem;
    height: 4rem;
    fill: var(--c-dark-perma);
  }
}
.themeNav--toggleBtnIcon {
  transition: transform .2s ease-in-out;

  .themeNav--collapsed & {
    transform: rotate(180deg);
  }
}
.themeNav--home {
  position: relative;
  z-index: 3;
  display: flex;
  font-size: 7rem;
  z-index: 5;
  background-color: var(--c-light-perma);
  align-items: center;
  border-radius: 100em;
  padding: .5rem;
  margin-right: 1.25rem;
  box-shadow: 0 0 3px 3px rgba(var(--c-dark-rgb), .2);

  a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9rem;
    height: 9rem;
    border-radius: 100em;
    margin-right: .25rem;


    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: rgba(var(--c-dark-rgb-perma), 0.08);
      fill: var(--c-dark);
      transform: none;
    }
    &:active {
      opacity: 1;
    }

    &.active {
      background-color: var(--c-dark-perma);

      svg {
        fill: var(--c-light-perma);
      }
      &:hover, &:active {
        background-color: var(--c-dark-perma);
      }
    }
  }
   svg {
     position: relative;
     width: 75%;
     text-align: center;
     fill: var(--c-dark-perma);
   }
}
.themeNav--colorsBtn {
  position: relative;
  transition: transform .5s ease-in-out;
  width: 10rem;
  height: 10rem;
  margin: 0;
  z-index: 1;

  svg {
    fill: none;
    filter: drop-shadow( 0 0 3.5px rgba(var(--c-dark-rgb), .6));
  }

  &:hover {
    transform: scale(1.2) rotate(180deg);
  }
  &:active {
    transition: transform .2s linear;
    transform: scale(1) rotate(0deg);
  }
}

// color styles
.theme--default {
  --c-bg-highlight: rgba(var(--c-peach-rgb), .4);
  --c-bg-highlight-rgb: rgba(var(--c-peach-rgb), .4);
}
.theme--black {
  --c-dark: #fff;
  --c-dark-rgb: #{rgbFromHex(#fff)};
  --c-light: #{$c-dark};
  --c-light-rgb: #{rgbFromHex($c-dark)};

  @include defineGrays();

  .comingSoonBadge {
    path {
      fill: var(--c-dark);
    }
  }
  img {
    filter: invert(1);

    &.monochromeImg {
      mix-blend-mode: lighten;
    }
  }
  .ignore--theme {
    --c-light: #{$c-light};
    --c-light-rgb: #{rgbFromHex($c-light)};
    --c-dark: #{$c-dark};
    --c-dark-rgb: #{rgbFromHex($c-dark)};

    @include defineGrays();
  }
}
.theme--peach {
  --c-light: #{$c-peach};
  --c-light-rgb: #{rgbFromHex($c-peach)};
  --c-dark: #{$c-purple};
  --c-dark-rgb: #{rgbFromHex($c-purple)};

  .comingSoonBadge {

    rect {
      stroke: var(--c-light);
      stroke-width: 1px;
    }
  }
  
  body {
    background-color: var(--c-peach);
  }
  img {
    mix-blend-mode: multiply;
  }
}
.theme--blue {
  --c-dark: #{$c-peach};
  --c-dark-rgb: #{rgbFromHex($c-peach)};
  --c-light: #{$c-blue};
  --c-light-rgb: #{rgbFromHex($c-blue)};
  --c-checkbox-selected: #{$c-red};
  --c-checkbox-selected-rgb: #{rgbFromHex($c-red)};

  @include defineGrays();

  img {
    filter: invert(1);

    &.monochromeImg {
      mix-blend-mode: screen;
    }
  }
  .comingSoonBadge {
    rect {
      fill: var(--c-dark);
      stroke: var(--c-light);
      stroke-width: 1px;
    }
    path {
      fill: var(--c-light);
    }
  }
}
.theme--blueText {
  --c-dark: #{$c-purple};
  --c-dark-rgb: #{rgbFromHex($c-purple)};
}
.theme--red {
  --c-light: #{$c-red};
  --c-light-rgb: #{rgbFromHex($c-red)};
  --c-dark: #{$c-peach};
  --c-dark-rgb: #{rgbFromHex($c-peach)};

  body {
    background-color: var(--c-red);
  }
  img {
    mix-blend-mode: multiply;
  }
}
.theme--yellow {
  --c-light: #{$c-yellow};
  --c-light-rgb: #{rgbFromHex($c-yellow)};
  body {
    background-color: var(--c-yellow);
  }
  img {
    mix-blend-mode: multiply;
  }
}
.theme--gray {
  --c-light: #{$c-gray-theme};
  --c-light-rgb: #{rgbFromHex($c-gray-theme)};
  body {
    background-color: $c-gray-theme;
  }
  img {
    mix-blend-mode: multiply;
  }
}
