
input[type=text], input[type=search], input[type=password], input[type=email], input[type=name], input[type=url], textarea, .StripeElement, .btn--inputSize  {
  position: relative;
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 1.1em .9em 1em;
  border-width: 1px;
  border-radius: 3px;
  -webkit-appearance: none;
  outline: none;
  font-size: 2rem;
  line-height: 1.3em;
  font-family: $font-main;

  &:focus, &.StripeElement--focus {
    border-color: var(--c-gray-3);
  }
  &::placeholder {
    color: var(--c-gray-6);
  }
  .form--small & {
    font-size: 1.6rem;
    padding: .75rem 1.1rem;
  }
  .textInput--pill & {
    border-radius: 10em;
    padding: .9em 1.4em .8em;
  }
}
input[type=text], input[type=search], input[type=password], input[type=email], input[type=name], input[type=url], textarea, .StripeElement {
  color: var(--c-dark);
  background-color: var(--c-gray-10);
  border: 1px solid var(--c-gray-8);

  .formInput--hasError & {
    border-color: var(--c-red);
  }
}
.formInput {
  margin-bottom: 3rem;
}
label, .radioButtons--label {
  display: block;
  font-size: 2rem;
  margin-bottom: .5em;
  font-weight: normal;
}
.radioButtons--option {
  position: relative;
  text-transform: none;
  // display: inline-block;
  display: block;
  padding-left: 1.3em;
}
.radioButtons--optionInput {
  position: absolute;
  display: inline-block;
  border: 1px solid var(--c-dark);
  width: .8em;
  height: .8em;
  border-radius: 10em;
  vertical-align: top;
  top: .22em;
  left: 0;

  .radioButtons--option:hover & {
    background-color: var(--c-gray-5);
    border-color: var(--c-gray-3);
  }
  .radioButton--option--checked &, .radioButton--option--checked:hover & {
    background-color: var(--c-checkbox-selected);
    border-color: var(--c-checkbox-selected);
  }
}
.radioButtons--optionLabel {
  cursor: pointer;
  font-size: 1em;
}
.radioButton--optionIndicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  height: 40%;
  border-radius: 10em;
  background-color: var(--c-light);
}
.radioButtons--optionHint {
  font-size: .7em;
}
.checkboxInput {
  position: relative;
  padding-left: 1.3em;

  .checkboxList & {
    margin-bottom: .1em;

    label {
      margin-bottom: 0;
    }
  }
}
.checkboxInput--input {
  position: absolute;
  top: .2em;
  left: 0;
  display: block;
  width: .8em;
  height: .8em;
  background-color: transparent;
  border: 1px solid var(--c-dark);
  border-radius: .1em;
}
.checkboxInput--inlineLabel {
  font-size: 1em;
  cursor: pointer;
  display: inline-block;
}
.checkboxInput--indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 60%;
  border-radius: .05em;

  .checkboxInput:hover & {
    background-color: var(--c-gray-5);
  }
  .checkboxInput--checked &, .checkboxInput--checked.checkboxInput:hover & {
    background-color: var(--c-checkbox-selected);
  }
}
.form--intro {
  margin-bottom: 4rem;
}
.formErrors {
  color: var(--c-red);
  font-size: 1.5rem;
  padding: .5em 0;
  text-align: left;
}
.formErrors--form {
  background-color: rgba(var(--c-red-rgb), .1);
  padding: 1em;
  margin-bottom: 4rem;
  border-radius: 2px;
  color: var(--c-red);
}
.form--inputGroup {
  margin-bottom: 3rem;
}
.form--inputGroup--last {
  margin-bottom: 0rem;
}
.form--actions {
  text-align: right;
  padding-top: 2rem;
}
.formInput--hint {
  color: var(--c-gray-5);
  font-size: .8em;
  padding-top: .5em;
  font-style: italic;
}
.form--inlineInputButton {
  position: relative;
  display: flex;
  width: 100%;
  padding-top: 1.6em;

  .formInput {
    width: 65%;
    margin-bottom: 0;
  }
  label {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1.8rem;
    font-size: .9em;
  }
  .btn {
    width: 35%;
    margin-left: .7em;
  }
  input, .btn {
    box-sizing: border-box;
    font-size: 1em;
    padding: .75em 1em .6em;
  }
}
.form--splitInputRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
}
select {
  box-sizing: border-box;
  font-size: 1.3em;
  padding: .2em;
  height: 1.8em;
  border-radius: .2em;
  max-width: 100%;

  @include breakpoint($media-sm) {
    font-size: .8em;
  }
}
.rangeInput {
  input {
    position: relative;
    top: -.2em;
  }
}
