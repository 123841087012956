// generator by http://danielstern.ca/range.css/?ref=css-tricks#/

// default
input[type=range] {
  width: 100%;
  margin: 4.5px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  background: var(--c-dark);
  border: 0;
  width: 100%;
  height: 1px;
  cursor: pointer;
}
input[type=range]::-webkit-slider-thumb {
  margin-top: -4.5px;
  width: 11px;
  height: 11px;
  background: var(--c-dark);
  border: 0px solid rgba(0, 0, 0, 0);
  border: 0;
  border-radius: 11px;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--c-dark);
}
input[type=range]::-moz-range-track {
  background: var(--c-dark);
  border: 0;
  width: 100%;
  height: 1px;
  cursor: pointer;
}
input[type=range]::-moz-range-thumb {
  width: 11px;
  height: 11px;
  background: var(--c-dark);
  border: 0px solid rgba(0, 0, 0, 0);
  border: 0;
  border-radius: 11px;
  cursor: pointer;
}
input[type=range]::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 5.5px 0;
  color: transparent;
  width: 100%;
  height: 1px;
  cursor: pointer;
}
input[type=range]::-ms-fill-lower {
  background: var(--c-dark);
  border: 0;
}
input[type=range]::-ms-fill-upper {
  background: var(--c-dark);
  border: 0;
}
input[type=range]::-ms-thumb {
  width: 11px;
  height: 11px;
  background: var(--c-dark);
  border: 0px solid rgba(0, 0, 0, 0);
  border: 0;
  border-radius: 11px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type=range]:focus::-ms-fill-lower {
  background: var(--c-dark);
}
input[type=range]:focus::-ms-fill-upper {
  background: var(--c-dark);
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align:auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type=range] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}

@include breakpoint($media-md) {
  input[type=range] {
    width: 100%;
    margin: 6px 0;
    background-color: transparent;
    -webkit-appearance: none;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    background: var(--c-dark);
    border: 0;
    border-radius: 25px;
    width: 100%;
    height: 4px;
    cursor: pointer;
  }
  input[type=range]::-webkit-slider-thumb {
    margin-top: -6px;
    width: 16px;
    height: 16px;
    background: var(--c-dark);
    border: 3.1px solid var(--c-dark);
    border-radius: 50px;
    cursor: pointer;
    -webkit-appearance: none;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: var(--c-dark);
  }
  input[type=range]::-moz-range-track {
    background: var(--c-dark);
    border: 0;
    border-radius: 25px;
    width: 100%;
    height: 4px;
    cursor: pointer;
  }
  input[type=range]::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: var(--c-dark);
    border: 3.1px solid var(--c-dark);
    border-radius: 50px;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 6px 0;
    color: transparent;
    width: 100%;
    height: 4px;
    cursor: pointer;
  }
  input[type=range]::-ms-fill-lower {
    background: var(--c-dark);
    border: 0;
    border-radius: 50px;
  }
  input[type=range]::-ms-fill-upper {
    background: var(--c-dark);
    border: 0;
    border-radius: 50px;
  }
  input[type=range]::-ms-thumb {
    width: 16px;
    height: 16px;
    background: var(--c-dark);
    border: 3.1px solid var(--c-dark);
    border-radius: 50px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
  }
  input[type=range]:focus::-ms-fill-lower {
    background: var(--c-dark);
  }
  input[type=range]:focus::-ms-fill-upper {
    background: var(--c-dark);
  }
  /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
  @supports (-ms-ime-align:auto) {
    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
    input[type=range] {
      margin: 0;
      /*Edge starts the margin from the thumb, not the track as other browsers do*/
    }
  }


  // smaller
  .rangeInput--small { 
    input[type=range] {
      width: 100%;
      margin: 5px 0;
      background-color: transparent;
      -webkit-appearance: none;
    }
    input[type=range]:focus {
      outline: none;
    }
    input[type=range]::-webkit-slider-runnable-track {
      background: var(--c-dark);
      border: 0;
      border-radius: 1.3px;
      width: 100%;
      height: 2px;
      cursor: pointer;
    }
    input[type=range]::-webkit-slider-thumb {
      margin-top: -5px;
      width: 12px;
      height: 12px;
      background: var(--c-dark);
      border: 0;
      border-radius: 50px;
      cursor: pointer;
      -webkit-appearance: none;
    }
    input[type=range]:focus::-webkit-slider-runnable-track {
      background: var(--c-dark);
    }
    input[type=range]::-moz-range-track {
      background: var(--c-dark);
      border: 0;
      border-radius: 1.3px;
      width: 100%;
      height: 2px;
      cursor: pointer;
    }
    input[type=range]::-moz-range-thumb {
      width: 12px;
      height: 12px;
      background: var(--c-dark);
      border: 0;
      border-radius: 50px;
      cursor: pointer;
    }
    input[type=range]::-ms-track {
      background: transparent;
      border-color: transparent;
      border-width: 5px 0;
      color: transparent;
      width: 100%;
      height: 2px;
      cursor: pointer;
    }
    input[type=range]::-ms-fill-lower {
      background: var(--c-dark);
      border: 0;
      border-radius: 2.6px;
    }
    input[type=range]::-ms-fill-upper {
      background: var(--c-dark);
      border: 0;
      border-radius: 2.6px;
    }
    input[type=range]::-ms-thumb {
      width: 12px;
      height: 12px;
      background: var(--c-dark);
      border: 0;
      border-radius: 50px;
      cursor: pointer;
      margin-top: 0px;
      /*Needed to keep the Edge thumb centred*/
    }
    input[type=range]:focus::-ms-fill-lower {
      background: var(--c-dark);
    }
    input[type=range]:focus::-ms-fill-upper {
      background: var(--c-dark);
    }
    /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
    how to remove the virtical space around the range input in IE*/
    @supports (-ms-ime-align:auto) {
      /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
      input[type=range] {
        margin: 0;
        /*Edge starts the margin from the thumb, not the track as other browsers do*/
      }
    }
  }
}