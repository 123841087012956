.typefacePage {
  & main {
    position: relative;
    padding: 7.5rem $main-padding-horizontal 12rem;
  }

  h1 {
    position: relative;
    top: .05em;
    margin-bottom: 0;
  }
}
.typefacePage--headerContents {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 1rem;
  padding-bottom: 7rem;
  border-bottom: 2px solid var(--c-gray-1)0;
}
.typefacePage--header--sticky {
  position: fixed;
  pointer-events: none;
  opacity: 0;
  transition: opacity .5s;
  top: 0;
  right: 0;
  left: 0;
  background-color: var(--c-light);
  z-index: 10;
  padding: 1.5rem $main-padding-horizontal;
  border-bottom: 1px solid var(--c-gray-8);

  h1 {
    font-size: 5.6rem;
    cursor: pointer;
  }
  .sticky--active & {
    opacity: 1;
    pointer-events: initial;
  }
}
.typefacePage--header--right {
  position: relative;
  top: -.1em;

  .typefacePage--header--sticky & {
    top: 0;
  }
  .btn {
    display: inline-block;
    margin-left: .5em;

    &:first-child {
      margin-left: 0;
    }
  }
}

// intro
.typefacePage--intro {
  position: relative;
  padding-bottom: .75rem;

  .slideshow {
    margin-bottom: 5rem;
  }
}
.typefacePage--intro--description {
  font-size: 3.6rem;
  margin-bottom: 5.5rem;
}
// styles index
.typefacePage--stylesIndex {
  h3 {
    margin-bottom: 1.5em;
  }
  border-top: 1px solid var(--c-gray-8);
  padding-top: 2rem;
  margin-bottom: 7rem;
}
.typefacePage--stylesIndex--list {
  font-size: 7rem;
  line-height: 1.1em;
  max-width: 12em;

  .typefacePage--kicker & {
    font-size: 12rem;
  }
  .typefacePage--analog & {
    font-size: 6rem;
    max-width: 22em;
  }
  .typefacePage--rainer & {
    font-size: 10rem;
    line-height: 8.4rem;
    max-width: 15em;
    margin-top: 3.5rem;
  }
}
.typefacePage--stylesIndex--style {
  display: inline-block;
  margin-right: .25em;
  white-space: nowrap;

  &:last-child {
    .typefacePage--stylesIndex--separator {
      display: none;
    }
  }
}

// tester
.fontTester {
  .typefacePage--whoa & {
    padding-top: 3rem;
    padding-bottom: 2.5rem;
  }
  .typefacePage--wildberry & {
    padding-top: 6.5rem;
    padding-bottom: 5.5rem;
  }
}

.fontTester--variable {
  .typefacePage--whoa & {
    .fontTester--input {
      padding-bottom: .05em;
    }
    & .fontTester {
      padding-top: 0rem;
    }
  }
}

// Images
.typefacePage--images {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 0rem;
  row-gap: 8rem;
  border-bottom: 1px solid var(--c-gray-8);
  padding-bottom: 7rem;

  @include breakpoint($media-sm) {
    column-gap: 8rem;
  }

}
.typefacePage--image {
  position: relative;
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
.typefacePage--image--size-1 {
  grid-column: span 12;
}
.typefacePage--image--size-2 {
  grid-column: span 12;

  @include breakpoint($media-sm) {
    grid-column: span 6;
  }
}
.typefacePage--image {
  border-top: 1px solid var(--c-gray-8);
  padding-top: 2rem;
}
.typefacePage--image--caption {
  margin-bottom: 2rem;
}

// info
.typefacePage--info--section {
  padding-top: 2rem;
  border-top: 1px solid var(--c-gray-8);

  &:first-child {
    border-top: none;
  }
  h3 {
    margin-bottom: 1.5rem;
  }
  h6 {
    margin-bottom: 8rem;
  }
}
.typefacePage--info--topContent {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 2rem;
  row-gap: 4rem;
  margin-bottom: 8rem;
  @include breakpoint($media-sm) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.typefacePage--info--description {
  grid-column: 1 / 3;
  font-size: 2.6rem;
  padding-right: 1em;
}
.typefacePage--info--credit {
  @include breakpoint($media-sm) {
    font-size: 2rem;
  }
}

.typefacePage--info--bottomSection {
  border-top: 1px solid var(--c-gray-8);
  padding-top: 2rem;
}
.typefacePage--info--bottomContent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 4rem;
  row-gap: 6rem;

  @include breakpoint($media-sm) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.typefacePage--info--bottomCol {
  @include breakpoint($media-sm) {
    font-size: 2rem;
  }
}
