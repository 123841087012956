.dropdown {
  position: relative;
}
.dropdown--trigger {
  cursor: pointer;
  display: inline-block;
}
.dropdown--options {
  display: none;
  position: absolute;
  right: 0;
  margin-top: 0rem;
  padding: 2.5rem 3.5rem;
  font-size: 2rem;
  background-color: var(--c-light);
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 2px 0 rgba(var(--c-dark-rgb),0.14), 0 3px 1px -2px rgba(var(--c-dark-rgb),0.12), 0 1px 5px 0 rgba(var(--c-dark-rgb),0.20);
  min-width: 5em;
  line-height: 1.8;
  left: -2.5em;

  list-style-type: none;
  z-index: 10;

  .dropdown--active & {
    display: block;
  }

  li {
    padding: 0em 0;
  }
}
