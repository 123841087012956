.backendLayout--inner {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 2rem;
}
.backendNav {
  padding: 7.5rem 0 0rem;
  font-size: 2.8rem;
  line-height: 1em;
  text-transform: uppercase;
  grid-column: 2 / 12;

  @include breakpoint($media-sm) {
    padding: 7.5rem 4rem 5rem;
    grid-column: 1 / 4;
    border-right: 1px solid var(--c-gray-8);
    min-height: 50vh;
  }
  @include breakpoint($media-md) {
    // padding: 7.5rem 4rem 5rem;
    grid-column: 1 / 3;
  }
  li {
    display: inline-block;
    position: relative;
    top: .5rem;
    list-style-type: none;
    margin-bottom: 1em;
    padding: 0;
    margin-right: 3rem;

    @include breakpoint($media-sm) {
      margin-right: 0;
      display: block;
    }
  }

  a {
    text-decoration: none;
    color: var(--c-dark);

    &.active {
      text-decoration: underline;
    }
  }
}
.backendLayout--content {
  padding: 7rem 0;
  grid-column: 2 / 12;

  @include breakpoint($media-sm) {
    padding: 7rem 4rem 12rem;
    grid-column: 4 / 13;
  }
  h2 {
    margin-bottom: 1em;
  }
}


