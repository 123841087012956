.siteBanner {
  background-color: var(--c-purple);
  color: var(--c-light);
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  font-size: 2rem;
}
@keyframes siteBanner--animation {
  0% {
    left: 0%;
  }
  100% {
    left: -200%;
  }
}
.siteBanner--scroller {
  color: var(--c-light);
  text-decoration: none;
  display: inline-block;
  position: relative;
  padding: .2em;
  animation: siteBanner--animation 120s forwards infinite linear;
  white-space: nowrap;

  &:hover {
    opacity: .8;
  }
  &:active {
    opacity: .6;
  }
}