header {
  border-bottom: 1px solid var(--c-gray-8);

  &.header--expanded {
    border-bottom: none;
  }
}
nav {
  box-sizing: border-box;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: .55em $main-padding-horizontal .55em;
  font-size: 3.4rem;
  line-height: 1em;

  @include breakpoint($media-xs) {
    font-size: 4rem;
  }

  > a {
    white-space: nowrap;
  }
  a, .btn--normalLink {
    font-weight: normal;
    display: inline-block;
    box-sizing: border-box;
    color: var(--c-dark);
    text-transform: uppercase;
    text-decoration: none;
    line-height: 1.2em;
    margin-bottom: 0;
    
    &:hover {
      color: var(--c-dark);
      text-decoration: none;  
      transform: none;
    }
    &:active {
      opacity: 1;
      transform: none;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    &.active {
      @include max_breakpoint($media-sm) {
        font-weight: 600;
      }
    }
  }

  .dropdown {
    display: inline-block;
    margin: 0;
    

    button {
      margin: 0;
    }
  }
  .dropdown--options {
    text-align: left;
    font-size: 3rem;
    left: auto;

    @include breakpoint($media-sm) {
        font-size: 2rem;
      }
    }
}
.fontsNav--wrap {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  &.fontsNav--expanded {
    display: block;
  }
}
.nav--radioBtn {
  position: relative;
  display: none;
  top: .01em;
  width: .55em;
  height: .55em;
  border-radius: 1em;
  border: .05em solid var(--c-dark);
  margin-right: .3em;

  @include breakpoint($media-sm) {
    display: inline-block;
  }

  .active &,  a:hover & {
    background-color: var(--c-dark);
    &:after {
      content: '';
      background-color: var(--c-light);
      width: .2em;
      height: .2em;
      border-radius: 1em;
      position: absolute;
      top: ((.55 - .2) / 2) * 1em;
      left: ((.55 - .2) / 2) * 1em;
    }
  }
  a:active & {
    opacity: .8;
  }

}
.nav--account .dropdown--trigger, button, .nav--cartBtn {
  padding: .3em .42em .3em .4em;
  border-radius: .5em;
  border: none;

  &:hover {
    color: var(--c-dark);
  }
}
.nav--account {
  display: inline-block;
}
.nav--cartBtn {
  background-color: var(--c-gray-9);
  &:hover {
    background-color: var(--c-gray-8);
  }
  &:active {
    background-color: var(--c-gray-9);
  }
  .icon {
    position: relative;
    top: .075em;
    height: .9em;
    margin-right: .1em;
  }
}