.pageSpotlight {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}
.pageSpotlight--bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.pageSpotlight--message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  clip-path: url(#spotlightClipPath);
  pointer-events: none;
}
.pageSpotlight--message--item {
  &:nth-child(4n+1){
    font-variation-settings: 'MORF' 20;
  }
  &:nth-child(4n+2){
    font-variation-settings: 'MORF' 40;
  }
  &:nth-child(4n+3){
    font-variation-settings: 'MORF' 60;
  }
}
.pageSpotlight--message--content {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: var(--c-purple);
  font-family: 'Kablammo Web';
  text-align: center;
  line-height: 1em;
  font-size: 20vw;
  overflow: hidden;

  p {
    margin-bottom: 0;
  }
}