$screensaver-bg: $c-peach;
$screensaver-shadow1: $c-red;
$screensaver-shadow2: $c-cream;
$screensaver-top: $c-purple;

$threeD_screensaver_frames: (
  (0%, 48deg, 48deg), 
  (25%, -30deg, -88deg),
  (50%, 30deg, 38deg),
  (75%, -75deg, -30deg),
  (100%, 48deg, 48deg)
);

// animation for top layer
@keyframes screensaver-3d-text-top {
  @each $pct, $x, $y in $threeD_screensaver_frames {
    #{$pct} {
      transform: rotateX($x) rotateY($y);
    }
  }
}

// animation for the layers
$ss-3d-layers: 30;
$ss-3d-layers-offset: -2px;
@for $i from 1 through $ss-3d-layers {
  @keyframes screensaver-3d-text-layer-#{$i} {
    @each $pct, $x, $y in $threeD_screensaver_frames {
      #{$pct} {
        transform: rotateX($x) rotateY($y) translateZ($ss-3d-layers-offset * $i);
      }
    }
  }
}
@mixin perspective {
  position: absolute;
  perspective: 1.5em;
  perspective-origin: 50% 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3em;
  height: 1em;
  text-align: center;
  line-height: 1em;
  font-size: 20em;
}
.threeDScreensaver {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-family: 'VCTR Serif Web';
  font-weight: bold;
  // font-size: .68vw;
  font-size: 2vw;
  pointer-events: none;
  background-color: $c-peach;
  z-index: 1000;
}
.threeDScreenSaver--container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: rotate(90deg);
  @include breakpoint($media-sm) {
    font-size: 1vw;
    // font-size: .7vw;
    transform: rotate(0deg);
  }
}
.threeDScreensaver--top, .threeDScreensaver--shadow {
  @include perspective;
}
.threeDScreensaver--topText, .threeDScreensaver--shadowTextLayer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-variant-ligatures: discretionary-ligatures; 
}
.threeDScreensaver--top {
  color: $screensaver-top;
}
.threeDScreensaver--shadow {
  color: $screensaver-shadow1;
  opacity: .8;
  .browser-ios &, .browser-safari & {
    text-shadow: .01em .01em 0em rgba($screensaver-shadow2, .5);
  }
  
}
.threeDScreensaver--animating {
  .threeDScreensaver--topText {
  
    animation: screensaver-3d-text-top 15s linear infinite;
  }
  .threeDScreensaver--shadowTextLayer {
    @for $i from 1 through $ss-3d-layers {
      &:nth-child(#{$i}n) {
        animation: screensaver-3d-text-layer-#{$i} 15s linear infinite;
      }
    }
  }
}
