.licensingPage {
  padding: 7.5rem $main-padding-horizontal 7.5rem;
  font-size: 2.5rem;
  grid-template-columns: repeat(12, 1fr);

  h2 {
    margin-top: 10rem;
    margin-bottom: 5rem;
    padding-top: 5rem;

    border-top: 1px solid var(--c-dark);

    &:first-child {
      margin-top: none;
    }
  }
  h3 {
    padding-top: 3rem;
    margin-bottom: 2rem;
  }

  li {
    margin-bottom: 1em;
  }
  
}
.licensingPage--main {
  @include blog-page-grid();
  grid-column: 1 / 13;
  > * {
    grid-column: 2 / 12;
    
    @include breakpoint($media-md) {
      grid-column: 3 / 11;
    }
  }
}
.licensingPage--overview {
  padding-top: 5rem;
}
.licensingPage--overview--licenses {
  h2 {
    border-top: 0;
  }
  ul {
    list-style-type: none;
    margin-bottom: 4rem;
  }
  li {
    margin-bottom: .2em;
  }
}
.licensingPage--overview--special {
  ul {
    margin-top: 2em;
  }
}
.licensingPage--eulaBtn {
  margin-top: 1rem;
}
.licensingPage--faq {
  li {
    font-weight: 600;
    li {
      font-weight: normal;
    }
  }
}