.adminOrders--orderItem {
  margin-bottom: .5em;

  &:last-child {
    margin-bottom: 0;
  }
}
.adminOrders--ordersList {
  .td-total_cost_in_dollars {
    text-align: right;
  }
  .td-actions {
    position: relative;
    top: -.5em;
  }

  tbody :last-child {
    border-bottom: none;
  }
}
.adminOrders--orderItem--meta {
  font-size: 1.8rem;
  margin-bottom: .5em;

}
.adminTrialDownloads--list {
    font-size: 1.8rem;
}
.adminTrialDownloads--testBtn {
  margin-bottom: 3rem;
}