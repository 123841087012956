.btn {
  transition: transform .2s ease-in-out;
  box-sizing: border-box;
  display: inline-block;
  font-family: $font-main;
  font-weight: 500;
  background-color: var(--c-dark);
  color: var(--c-light);
  padding: .8em 2em;
  text-align: center;
  border: .1em solid var(--c-dark);
  cursor: pointer;
  white-space: nowrap;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-decoration: none;
  border-radius: 100em;
  line-height: 1.2em;

  svg {
    fill: var(--c-light);
  }

  &.btn--inputSize {
    border-radius: 100em;
  }

  &:hover {
    text-decoration: none;
    color: var(--c-light);
    transform: scale(1.1);
  }
  &:active {
    transform: scale(.95);
  }

  &:disabled {
    background-color: var(--c-gray-8);
    color: var(--c-gray-5);
    border-color: transparent;
    cursor: auto;

    svg {
      fill: var(--c-gray-5);
    }

    &:hover, &:active {
      opacity: 1;
      background-color: var(--c-gray-8);
      color: var(--c-gray-5);
      transform: scale(1);
    }
  }
}
.btn--outline {
  background-color: transparent;
  color: var(--c-dark);

  svg {
    fill: var(--c-dark);
  }

  &:hover {
    background-color: var(--c-dark);
    color: var(--c-light);
    opacity: 1;

    svg {
      fill: var(--c-light);
    }
  }
  &:active {
    opacity: .7;
  }
  &:disabled {
    &, &:hover, &:active {
      
      background-color: transparent;
      color: var(--c-gray-8);
      border-color: var(--c-gray-8);
    }
  }
}
.btn--oval {
  border-radius: 100%;
}
.btn--large {
  font-size: 2.6rem;
}
.btn--small {
  font-size: 2rem;
}
.btn--superSmall {
  font-size: 1.4rem;
  padding: .5em 1em;
}
.btn--normalLink {
  background-color: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.btn--fullWidth {
  width: 100%;
}
.btn--noStyle {
  display: inline-block;
  font-family: $font-main;
  font-weight: normal;
  background-color: transparent;
  color: var(--c-dark);
  padding: 0;
  text-align: center;
  border: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-decoration: none;
}
