@keyframes growler-entry {
  0% {
      right: -100%;
  }
  20% {
    right: 2.5rem;
  }
  90% {
    opacity: 1;
    right: 2.5rem;
  }
  100% {
    opacity: 0;
    right: 2.5rem;
  }
}
.growler {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--c-gray-9);
    border-radius: 10em;
  }
  animation: growler-entry 5s ease forwards;
  position: fixed;
  z-index: 1000;
  top: 1.75rem;
  right: -100%;
  background-color: var(--c-light);
  color: var(--c-dark);
  border-radius: 10em;
  padding: .8em 2em;
  font-size: 2rem;
  box-shadow: 0 0 5px 2px rgba(var(--c-dark-rgb), .2);
}