@mixin declare_font($path, $family, $weight:normal, $style:normal, $format: 'woff2') {
  @font-face {
    font-family: $family;
    src: url('#{$fonts-path}#{$path}.#{$format}') format('#{$format}');
    font-weight: $weight;
    font-display: block;
    font-style: $style;
  }
}
@mixin declare_font_with_fallback($path, $family, $weight, $style, $format, $path2, $format2) {
  @font-face {
    font-family: $family;
    src: url('#{$fonts-path}#{$path}.#{$format}') format('#{$format}'),
      url('#{$fonts-path}#{$path2}.#{$format2}') format('#{$format2}');
    font-weight: $weight;
    font-display: block;
    font-style: $style;
  }
}
@include declare_font('VCTRMono-Thin', 'VCTR Mono Web', 200, normal, 'woff2');
@include declare_font('VCTRMono-ThinItalic', 'VCTR Mono Web', 200, italic, 'woff2');
@include declare_font('VCTRMono-Light', 'VCTR Mono Web', 300, normal, 'woff2');
@include declare_font('VCTRMono-LightItalic', 'VCTR Mono Web', 300, italic, 'woff2');
@include declare_font('VCTRMono-Regular', 'VCTR Mono Web', 400, normal, 'woff2');
@include declare_font('VCTRMono-RegularItalic', 'VCTR Mono Web', 400, italic, 'woff2');
@include declare_font('VCTRMono-Medium', 'VCTR Mono Web', 500, normal, 'woff2');
@include declare_font('VCTRMono-MediumItalic', 'VCTR Mono Web', 500, italic, 'woff2');
@include declare_font('VCTRMono-Bold', 'VCTR Mono Web', 600, normal, 'woff2');
@include declare_font('VCTRMono-BoldItalic', 'VCTR Mono Web', 600, italic, 'woff2');
@include declare_font('VCTRMono-Black', 'VCTR Mono Web', 800, normal, 'woff2');
@include declare_font('VCTRMono-BlackItalic', 'VCTR Mono Web', 800, italic, 'woff2');

@include declare_font('Kablammo[MORF]', 'Kablammo Web', 400, normal, 'woff2');

@include declare_font('ChartwellPies', 'Chartwell Pies Web', 400, normal, 'woff2');
@include declare_font('Kicker-Variable', 'Kicker Variable Web', 400, normal, 'woff2');

@include declare_font_with_fallback('WHOA-Top-v1.0-VF-cff', 'WHOA Top Web', 400, normal, 'woff2', 'WHOA-Top-v1.0-VF-ttf', 'woff2');
@include declare_font_with_fallback('WHOA-Spine-v1.0-VF-cff', 'WHOA Spine Web', 400, normal, 'woff2', 'WHOA-Spine-v1.0-VF-ttf', 'woff2');
// @include declare_font_with_fallback('WHOA-Cosmographs-v1.0-VF-cff', 'WHOA Cosmographs Web', 400, normal, 'woff2', 'WHOA-Cosmographs-v1.0-VF-ttf', 'woff2');

// @include declare_font_with_fallback('WHOA-Top-v0.4', 'WHOA Top Web', 400, normal, 'woff2', 'WHOA-Top-v0.4.1-ttf', 'woff2');
// @include declare_font_with_fallback('WHOA-Spine-v0.4', 'WHOA Spine Web', 400, normal, 'woff2', 'WHOA-Spine-v0.4.1-ttf', 'woff2');

@include declare_font('WildberryVariableVF', 'Wildberry Variable Web', 400, normal, 'woff2');

@include declare_font('Analog-Thin', 'Analog Web', 200, normal, 'woff2');
@include declare_font('Analog-Light', 'Analog Web', 300, normal, 'woff2');
@include declare_font('Analog-Regular', 'Analog Web', 400, normal, 'woff2');
@include declare_font('Analog-Medium', 'Analog Web', 500, normal, 'woff2');
@include declare_font('Analog-Bold', 'Analog Web', 600, normal, 'woff2');

@include declare_font('Analog-ThinOblique', 'Analog Web', 200, italic, 'woff2');
@include declare_font('Analog-LightOblique', 'Analog Web', 300, italic, 'woff2');
@include declare_font('Analog-RegularOblique', 'Analog Web', 400, italic, 'woff2');
@include declare_font('Analog-MediumOblique', 'Analog Web', 500, italic, 'woff2');
@include declare_font('Analog-BoldOblique', 'Analog Web', 600, italic, 'woff2');

@include declare_font('VCTRSerif0.1-Bold4', 'VCTR Serif Web', bold, normal, 'woff2');

@include declare_font('Rainer-Hairline', 'Rainer Web', 100, normal, 'woff2');
@include declare_font('Rainer-Thin', 'Rainer Web', 200, normal, 'woff2');
@include declare_font('Rainer-Light', 'Rainer Web', 300, normal, 'woff2');
@include declare_font('Rainer-Regular', 'Rainer Web', 400, normal, 'woff2');
@include declare_font('Rainer-Medium', 'Rainer Web', 500, normal, 'woff2');
@include declare_font('Rainer-Bold', 'Rainer Web', 600, normal, 'woff2');

@include declare_font('Rainer-HairlineSlanted', 'Rainer Web', 100, italic, 'woff2');
@include declare_font('Rainer-ThinSlanted', 'Rainer Web', 200, italic, 'woff2');
@include declare_font('Rainer-LightSlanted', 'Rainer Web', 300, italic, 'woff2');
@include declare_font('Rainer-RegularSlanted', 'Rainer Web', 400, italic, 'woff2');
@include declare_font('Rainer-MediumSlanted', 'Rainer Web', 500, italic, 'woff2');
@include declare_font('Rainer-BoldSlanted', 'Rainer Web', 600, italic, 'woff2');


@include declare_font('Chartwell-Arcs', 'Chartwell Arcs Web', normal, normal, 'woff2');
@include declare_font('Chartwell-Areas', 'Chartwell Areas Web', normal, normal, 'woff2');
@include declare_font('Chartwell-AreasRanges', 'Chartwell AreasRanges Web', normal, normal, 'woff2');
@include declare_font('Chartwell-Bars', 'Chartwell Bars Web', normal, normal, 'woff2');
@include declare_font('Chartwell-Bars3D', 'Chartwell Bars3D Web', normal, normal, 'woff2');
@include declare_font('Chartwell-Histograms', 'Chartwell Histograms Web', normal, normal, 'woff2');
@include declare_font('Chartwell-LinesA', 'Chartwell LinesA Web', normal, normal, 'woff2');
@include declare_font('Chartwell-LinesB', 'Chartwell LinesB Web', normal, normal, 'woff2');
@include declare_font('Chartwell-LinesC', 'Chartwell LinesC Web', normal, normal, 'woff2');
@include declare_font('Chartwell-LinesD', 'Chartwell LinesD Web', normal, normal, 'woff2');
@include declare_font('Chartwell-LinesE', 'Chartwell LinesE Web', normal, normal, 'woff2');
@include declare_font('Chartwell-LinesF', 'Chartwell LinesF Web', normal, normal, 'woff2');
@include declare_font('Chartwell-Pies', 'Chartwell Pies Web', normal, normal, 'woff2');
@include declare_font('Chartwell-ProgressBars', 'Chartwell ProgressBars Web', normal, normal, 'woff2');
@include declare_font('Chartwell-ProgressBoxes', 'Chartwell ProgressBoxes Web', normal, normal, 'woff2');
@include declare_font('Chartwell-ProgressDots', 'Chartwell ProgressDots Web', normal, normal, 'woff2');
@include declare_font('Chartwell-ProgressSquares', 'Chartwell ProgressSquares Web', normal, normal, 'woff2');
@include declare_font('Chartwell-ProgressStars', 'Chartwell ProgressStars Web', normal, normal, 'woff2');
@include declare_font('Chartwell-Radar', 'Chartwell Radar Web', normal, normal, 'woff2');
@include declare_font('Chartwell-Rings', 'Chartwell Rings Web', normal, normal, 'woff2');
@include declare_font('Chartwell-Rose', 'Chartwell Rose Web', normal, normal, 'woff2');
@include declare_font('Chartwell-Scatter', 'Chartwell Scatter Web', normal, normal, 'woff2');
@include declare_font('Chartwell-StepsA', 'Chartwell StepsA Web', normal, normal, 'woff2');
@include declare_font('Chartwell-StepsB', 'Chartwell StepsB Web', normal, normal, 'woff2');
@include declare_font('Chartwell-StepsC', 'Chartwell StepsC Web', normal, normal, 'woff2');
@include declare_font('Chartwell-StepsD', 'Chartwell StepsD Web', normal, normal, 'woff2');
@include declare_font('Chartwell-StepsE', 'Chartwell StepsE Web', normal, normal, 'woff2');
@include declare_font('Chartwell-StepsF', 'Chartwell StepsF Web', normal, normal, 'woff2');
