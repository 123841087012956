table {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border-collapse: collapse;
  border-top: 1px solid var(--c-gray-8);
}
th {
  font-weight: normal;
  text-transform: uppercase;
}
tr {
  border-bottom: 1px solid var(--c-gray-8);
}
th, td {
  padding: 2.5rem .5rem;
  text-align: left;
  vertical-align: top;

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
}
tfoot {
  tr {
    border-bottom: none;
  }
  td {
    font-weight: bold;
  }
}
.table--actions, .td-actions {
  text-align: right;

  .btn {
    margin-left: .5rem;
  }
}
.table--filters {
  margin-bottom: 1rem;

  .btn {
    margin-right: .5rem;
    margin-bottom: .5rem;
  }
}
