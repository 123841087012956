html {
  position: relative;
  height: 100%;
  padding: 0;
  border: 0;
  margin: 0;
  z-index: 1;
  
  &.disableScroll {
    &, body {
      overflow: hidden;
    }
  }
  &.disableScrollMobile {
    &, body {
      overflow: hidden;

      @include breakpoint($media-sm){
        overflow: auto;
      }
    }
  }
}
body {
  position: relative;
  box-sizing: border-box;
  min-height: 100%;
  background-color: var(--c-light);
  padding: 0;
  border: 0;
  margin: 0;
}
main {
  display: block;
  overflow: hidden;
  min-height: 50vh;
}
img {
  max-width: 100%;
}
.monochromeImg {
  filter:  url('#monochrome-filter');
}
.narrowLayout {
  main {
    max-width: 70rem;
    padding: 10rem 5rem;
    margin: 0 auto;
  }
}
.svg-defs-only {
  height: 0;
  width: 0;
  position: fixed;
  top: -100%;
  left: -100%;
}
figure {
  position: relative;
  display: block;
  margin: 0;
  margin-bottom: 1em;

  > a, img {
    display: block;
  }
  img {
    margin: 0;
  }
}
figcaption {
  padding: 1em 0 .5em;
}
video {
  width: 100%;
  height: auto;
}
hr {
  background-color: var(--c-dark);
  height: 1px;
  border: none;
  margin: 4rem 0;
}
.basicPage {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 2rem;
  padding: 7.5rem $main-padding-horizontal 7.5rem;
}
.basicPage--content {
  grid-column: 1 / 13;
  @include breakpoint($media-xs) {
    grid-column: 2 / 12;
  }
  @include breakpoint($media-sm) {
    grid-column: 3 / 11;
  }

  h2 {
    margin-top: 4rem;
    margin-bottom: 1.5rem;
    font-weight: 500;

    &:first-child {
      margin-top: none;
    }
  }

  h3 {
    margin-top: 2rem;
    font-weight: normal;
  }
}