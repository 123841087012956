.pagination {
  position: relative;
  padding-top: 8rem;
}
.pagination--nav {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 3rem;
    font-size: 4.2rem;
    padding: 0;
    width: 1.5em;
    height: 1.5em;
    text-align: center;
    border-width: 2px;
    font-weight: 300;

    span {
      position: relative;
      top: .07em;
    }
  }
}