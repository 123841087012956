// blocks
.fontsPage {
  display: grid;
  grid-template-columns: 1fr;

  @include breakpoint($media-xs) {
    grid-template-columns: 1fr 1fr;
  }
  @include breakpoint($media-md) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .comingSoonBadge {
    position: absolute;
    top: 15%;
    right: 10%;
    width: 30%;
    transform: rotate(5deg);
  }

}
.fontsPage--block {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-bottom: 1px solid var(--c-gray-8);
  border-right: 1px solid var(--c-gray-8);
  padding-top: 6rem;
  padding-bottom: 5rem;
  text-decoration: none;
  font-weight: normal;
  text-align: center;
  background-color: var(--c-light);
  transition: background-color .2s linear;
  animation: fade-in .5s;

  &:hover {
    text-decoration: none;
    background-color: var(--c-bg-highlight-rgb);
  }
  &:active {
    text-decoration: none;
  }

  @include min_and_max_breakpoint($media-xs, $media-md) {
    &.fontsPage--block--lastRow2 {
      border-bottom: none;
    }
    &:nth-child(2n) {
      border-right: none;
    }
  }
  @include breakpoint($media-md) {
    &.fontsPage--block--lastRow3 {
      border-bottom: none;
    }
    &:nth-child(3n) {
      border-right: none;
    }
  }
}
.fontsPage--fillerBlock {
  border-bottom: none;
  &:hover {
    background-color: transparent;
  }
}
.fontsPage--fillerBlock2 {
  display: none;
  @include breakpoint($media-md) {
    display: block;
  }
}
.fontsPage--sampleWrap {
  transition: transform .2s linear;
}
.fontsPage--blockSampleText {
  position: relative;
  overflow: visible;
  pointer-events: none;
  font-size: 21rem;
  height: 28rem;

  line-height: 1em;

  .fontsPage--kablammo & {
    top: -.1em;
  }
  .fontsPage--whoa & {
    top: -.02em;
    font-size: 30rem;
    font-variation-settings: 'HRZN' 0, 'VERT' 1000, 'ROTA' 0, 'ZOOM' 0;
  }
  .fontsPage--analog & {
    top: -.05em;
    font-weight: bold;
    font-size: 18rem;
  }
  .fontsPage--kicker & {
    top: .05em;
    font-size: 32rem;
    line-height: .5em;
    font-variation-settings: 'LAY1' 1000, 'LAY2' 550, 'LAY3' 150;
  }
  .fontsPage--wildberry & {
    top: -.05em;
    font-variation-settings: 'wght' 800;
  }
  .fontsPage--rainer & {
    top: -.1em;
    font-size: 25rem;
  }
}
.fontsPage--blockInfo {
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
  line-height: 1.3em;
  
}
.fontsPage--blockTitle {
  text-transform: uppercase;
  font-size: 3.5rem;
  margin-bottom: .15em;
}
.fontsPage--blockCta {
  text-transform: uppercase;
  font-size: 2.4rem;
}
.fontsPage--blockImgWrap {
  width: 20vw;
  display: inline-block;
}
.fontsPage--blockImgWrap {
  position: relative;
  width: 100%;

  .fontsPage--chartwell & {
    width: 80%;
  }
}
