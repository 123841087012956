.md--image--section {
  padding-top: 2em;

  img {
    width: 100%;
  }

  & + * {
    padding-top: 1em;
  }
  & + & {
    padding-top: 0;
  }
}
.md--images--1, .md--images--2, .md--images--3 {
  a {
    display: block;
  }
}
.md--multipleImages {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
}
.md--images--1col {
  .md--naturalSizedImages & {
    text-align: center;

    figure {
      display: inline-block;
    }
    img {
      width: auto;
    }
  }
}
.md--images--2col {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
}
.md--images--3col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
}
.md--images--4col {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2rem;
}
