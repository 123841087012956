.designerAdminPayments--connectedMessage {
  margin-bottom: 2rem;
  padding: 1.5rem 2rem;
  border-radius: 2px;
}
.designerAdminPayments--connectedMessage--connected {
  background-color: rgba(var(--c-purple-rgb), .1);
  color: var(--c-purple);
}
.designerAdminPayments--connectedMessage--disconnected {
  background-color: rgba(var(--c-red-rgb), .1);
  color: var(--c-red);
}
.designerAdminPayments--intro {
  font-style: italic;
  margin-bottom: 1em;
}
.designerAdminOrders--total {
  text-align: right;
  padding-top: 3.8rem;
}