.icon {
  position: relative;
  top: .13em;
  height: 1em;
  display: inline-block;
}
svg {
  fill: var(--c-dark);
}
.icon--download {
  height: .9em;
}